import Submit from '~/components/button-submit';
import Card from '~/components/card';
import Form from '~/components/form';
import Field from '~/components/form-controls/field';

import FileUploader from '~/components/form-controls/file-uploader';
import TextArea from '~/components/form-controls/textarea';
import Page from '~/components/page';
import { useIsLoading } from '~/utils/routing';
import { useMetaActionResult } from './meta-action';
import { useMetaLoaderData } from './meta-loader';

export default function ReorderMeta() {
  const { requiredImgUploadCount, uploadFileInfo, ...values } =
    useMetaLoaderData();
  const actionResult = useMetaActionResult();
  const isLoading = useIsLoading();

  return (
    <Page headline="Anything else you can tell us?">
      <Card>
        <Form encType="multipart/form-data">
          <TextArea
            id="noteFromCustomer"
            name="noteFromCustomer"
            label="Additional comments"
            defaultValue={values.noteFromCustomer}
          />

          {uploadFileInfo && (
            <FileUploader
              accept={['image/jpeg', 'image/png', 'image/webp']}
              name="assets"
              requiredCount={requiredImgUploadCount}
              defaultValue={values.assets ?? []}
              description="Please upload clear images indicating damaged items."
              destination={uploadFileInfo.destination}
              storefrontId={uploadFileInfo.storefrontId}
            />
          )}
          <input
            type="hidden"
            name="requiredImgUploadCount"
            value={requiredImgUploadCount}
          />

          {actionResult?.ok === false && (
            <Field.ErrorText>{actionResult.message}</Field.ErrorText>
          )}
          <Submit loading={isLoading}>Next</Submit>
        </Form>
      </Card>
    </Page>
  );
}
