import { ComponentProps, FormEvent, useState } from 'react';
import { Form as RouterForm } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Alert from './alert';

type Props = ComponentProps<typeof RouterForm>;

export default function Form({
  children,
  className,
  method = 'POST',
  onInvalid,
  onChange,
  ...props
}: Props) {
  const [invalidFields, setInvalidFields] = useState(new Set<string>());

  const handleInvalid = (event: FormEvent<HTMLFormElement>) => {
    const { target } = event;
    const skip =
      !(target instanceof HTMLElement) ||
      (target instanceof HTMLInputElement && target.type === 'hidden') ||
      target.ariaHidden;

    if (!skip) {
      setInvalidFields(
        (prev) => new Set([...prev, `${target.getAttribute('name')}`]),
      );
    }

    onInvalid?.(event);
  };

  const handleChange = (event: FormEvent<HTMLFormElement>) => {
    const { target } = event;

    if (target instanceof HTMLElement) {
      setInvalidFields((prev) => {
        const next = new Set(prev);
        next.delete(`${target.getAttribute('name')}`);
        return next;
      });
    }

    onChange?.(event);
  };

  return (
    <RouterForm
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      method={method}
      onInvalid={handleInvalid}
      onChange={handleChange}
      className={twMerge('flex flex-col gap-6', className)}
    >
      {children}

      {invalidFields.size > 0 && (
        <Alert
          variant="error"
          title="Check all the required fields before continuing."
        />
      )}
    </RouterForm>
  );
}
