import Alert from '~/components/alert';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import Form from '~/components/form';
import LineItem from '~/components/line-item';
import Page from '~/components/page';
import PaymentSummary from '~/components/payment-summary';
import { useIsLoading } from '~/utils/routing';
import {
  FormKey,
  useWarrantyReviewActionResult,
} from './warranty-review-action';
import { useWarrantyReviewData } from './warranty-review-loader';

export default function WarrantyReview() {
  const { claims, paymentIntentSecret, fee } = useWarrantyReviewData();
  const actionResult = useWarrantyReviewActionResult();
  const isLoading = useIsLoading();

  return (
    <Page headline="Warranty Review">
      <Form className="grid grid-cols-1 gap-6">
        <Card headline="Items under warranty">
          {claims.map(
            ({
              id,
              quantity,
              lineItem: { name, imgUrl, optionsFromPlatform },
            }) => (
              <LineItem
                className="mt-4 border-b border-outline pb-4 last:border-none last:pb-0"
                variant="small"
                key={id}
                name={name ?? ''}
                imgSrc={imgUrl}
                quantity={quantity}
                options={optionsFromPlatform}
              />
            ),
          )}
        </Card>

        {fee && paymentIntentSecret && (
          <PaymentSummary amount={fee.amount} label={fee.displayName} />
        )}

        {actionResult && !actionResult.ok && (
          <Alert
            variant="error"
            className="mt-6"
            title="An error occurred while creating the claim. Please try again later."
          >
            <p>{actionResult.message}</p>
          </Alert>
        )}

        <input
          type="hidden"
          name={FormKey.paymentIntentSecret}
          value={paymentIntentSecret}
        />
        <input
          type="hidden"
          name={FormKey.paymentAmount}
          value={fee?.amount.value}
        />

        <Submit loading={isLoading}>Submit Warranty Request</Submit>
      </Form>
    </Page>
  );
}
