import { useIsLoading } from '~/utils/routing';
import Alert from './alert';
import Submit from './button-submit';
import Form from './form';
import Input from './form-controls/input';

type Props = {
  lookupIdentifierName: string;
  orderNumberName: string;
  orderNumberDescription?: string;
  errorMessage?: string;
  orderLookupLabel: string;
};

export default function OrderLookupForm({
  lookupIdentifierName,
  orderNumberName,
  orderNumberDescription,
  errorMessage,
  orderLookupLabel,
}: Props) {
  const isLoading = useIsLoading();

  return (
    <Form className="w-full items-start">
      <Input
        id={lookupIdentifierName}
        name={lookupIdentifierName}
        label="Email Address or Postal Code"
        autoComplete="email postal-code"
        required
        validationMessages={{
          valueMissing: 'Please enter your email address or postal code.',
        }}
      />

      <Input
        id={orderNumberName}
        name={orderNumberName}
        label={orderLookupLabel}
        description={`${orderNumberDescription ?? 'E.g. 123456, RS-98765, etc.'}`}
        required
        validationMessages={{
          valueMissing: 'Please enter your order number.',
        }}
      />

      {errorMessage && (
        <Alert variant="error" title="Something isn't quite right">
          <p>{errorMessage}</p>
        </Alert>
      )}
      <Submit loading={isLoading}>Look It Up</Submit>
    </Form>
  );
}
