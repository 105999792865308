import {
  createContext,
  InputHTMLAttributes,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';
import Field from './field';

type InputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'children' | 'hidden'
>;

const GroupContext = createContext<{
  selectedValue: string | null;
  isInvalid: boolean;
}>({ selectedValue: null, isInvalid: false });

export function ButtonOption({
  children,
  className,
  value,
  checked,
  defaultChecked,
  ...inputProps
}: PropsWithChildren<InputProps>) {
  const { selectedValue, isInvalid } = useContext(GroupContext);

  const isChecked =
    selectedValue ? value === selectedValue : checked || defaultChecked;

  return (
    <Field.Label
      className={twMerge(
        '-mb-[1px] flex cursor-pointer items-center gap-3 border border-gray-200 bg-white p-4 last:mb-0 last:rounded-b-md focus:outline-none [&:nth-child(1)]:rounded-t-md',
        isChecked && 'border-outline bg-surface',
        isInvalid && 'border-error ring-error',
        className,
      )}
    >
      <input
        {...inputProps}
        value={value}
        checked={checked}
        defaultChecked={defaultChecked}
        type="radio"
        className={
          isInvalid ?
            'border-error text-error focus:ring-error'
          : 'text-primary focus:ring-primary'
        }
      />
      {children}
    </Field.Label>
  );
}

export function CardOption({
  children,
  className,
  value,
  checked,
  defaultChecked,
  ...inputProps
}: PropsWithChildren<InputProps>) {
  const { selectedValue, isInvalid } = useContext(GroupContext);
  const isChecked =
    selectedValue ? value === selectedValue : checked || defaultChecked;

  return (
    <Field.Label
      className={twMerge(
        'cursor-pointer rounded-lg border border-outline p-6 shadow-sm focus-within:ring-offset-2 focus:outline-primary',
        isChecked && 'border-primary ring-2 ring-primary',
        isInvalid && 'border-error ring-error',
        className,
      )}
    >
      <input
        {...inputProps}
        value={value}
        checked={checked}
        defaultChecked={defaultChecked}
        type="radio"
        className="sr-only"
      />
      {children}
    </Field.Label>
  );
}

type ValidationConstraint = Extract<keyof ValidityState, 'valueMissing'>;

export default function RadioGroup({
  className = '',
  label,
  showLabel = true,
  children,
  disabled,
  validationMessage,
}: PropsWithChildren<{
  className?: string;
  label: string;
  showLabel?: boolean;
  disabled?: boolean;
  validationMessage?: Record<ValidationConstraint, string>;
}>) {
  const [isInvalid, setIsInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const providerState = useMemo(
    () => ({ selectedValue, isInvalid }),
    [selectedValue, isInvalid],
  );

  const handleInvalid = (event: React.FormEvent<HTMLFieldSetElement>) => {
    if (event.target instanceof HTMLInputElement) {
      setIsInvalid(!event.target.validity.valid);
      setErrorMessage(event.target.validationMessage);
    }
  };

  const handleChange = (event: React.FormEvent<HTMLFieldSetElement>) => {
    if (event.target instanceof HTMLInputElement) {
      setSelectedValue(event.target.value);
      setIsInvalid(!event.target.validity.valid);
    }
  };

  return (
    <fieldset
      disabled={disabled}
      className={className}
      onInvalid={handleInvalid}
      onChange={handleChange}
    >
      <legend
        className={twMerge(
          'text-sm font-medium text-gray-700',
          !showLabel && 'sr-only',
        )}
      >
        {label}
      </legend>
      <GroupContext.Provider value={providerState}>
        <div
        // this div is for the borders radius on the button options
        >
          {children}
        </div>
      </GroupContext.Provider>
      {!!isInvalid && (
        <Field.ErrorText className="mt-2">
          {validationMessage?.valueMissing ?? errorMessage}
        </Field.ErrorText>
      )}
    </fieldset>
  );
}
