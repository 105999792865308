import { crewCustomerApi } from 'corso-types';
import { zfd } from 'zod-form-data';
import api from '~/utils/api';
import {
  createActionResultHook,
  crewAction,
  error,
  json,
  withDraftClaim,
} from '~/utils/routing';
import { ActionErrorResult, ActionOkResult, ActionResult } from '~/utils/types';

export const ACTION_KEY = 'event';
const schema = zfd.formData({
  [ACTION_KEY]: zfd.json(
    crewCustomerApi['/:storefrontId/automations/after-reason-selection'].post
      .request.body,
  ),
});

export default crewAction(
  withDraftClaim(({ formData, claim, context }) => {
    const result = schema.safeParse(formData);

    if (!result.success) {
      return error(
        new Error('Malformed form data', {
          cause: {
            error: result.error,
            entries: Array.from(Object.entries(formData)),
          },
        }),
      );
    }

    const item = context.order?.lineItems.find(
      (lineItem) => lineItem.id === claim.lineItem.id,
    );
    if (!item) {
      return error(new Error('Line item not found on order.'));
    }

    if (!claim.reason) {
      return error(new Error('Missing claim reason.'));
    }

    return api
      .confirmAfterReasonSelection({
        params: { storefrontId: context.settings.storefrontId },
        body: result.data.event,
        query: {
          lineItemIdFromPlatform: claim.lineItem.idFromPlatform,
          quantity: claim.quantity,
          lineItemId: claim.lineItem.id,
          reasonId: claim.reason.id,
          claimType: claim.claimType,
          reasonCategoryCode: claim.reason.category,
          ...(claim.reason.detail && {
            reasonDetailId: claim.reason.detail.id,
          }),
        },
      })
      .then(() => {
        // item not eligible for return
        // order is cleared so that it will be reloaded in the order loader with the server changes to the line item for this event
        context.setOrder(null);
        // clean up the draft claim
        context.clearDraftClaim();
        // make sure if the claim was edited, is is also removed from the context
        context.removeLineItemClaim(claim.id);

        return json<ActionOkResult>({
          ok: true,
        });
      })
      .catch(() => json<ActionErrorResult>({ ok: false }));
  }),
);

export const useFollowUpActionResult = createActionResultHook<ActionResult>();
