import type { RouteObject } from 'react-router-dom';
import Spinner from '~/components/spinner';
import { CrewStore } from '~/stores/crew';
import api from '~/utils/api';
import { catchallRoute, MiddlewareFunction } from '~/utils/routing';
import { defaultTheme, setTheme } from '~/utils/theme';
import CrewLookup from './[:store]';
import Component from './app-shell-component';
import loader from './app-shell-loader';
import ErrorPage from './error';
import Reorder from './reorder';
import GspLookup from './root';

/**
 * Middleware for the app shell route runs before all loaders.
 * Sets the theme and in the future may initialize the store.
 * The context is passed as handler context to all data functions
 * and can be used to share data/state.
 * TODO: add store initialization and optimization
 *
 */
const middleware: MiddlewareFunction = async ({ params }, context) => {
  if (!params.store) {
    setTheme(defaultTheme);

    return context;
  }

  // * These are first level paths in the app that should not be used as store names for instance /order/order/:orderId
  // really don't like having this here vs with the router, but it is the central path for the app
  const invalidKeywords = ['order', 'claim', 'inactive', 'register', 'reorder'];

  if (invalidKeywords.includes(params.store)) {
    throw new RangeError('The store name must not be a reserved keyword', {
      cause: params.store,
    });
  }

  const settings = await api.fetchSettings({
    params: { slug: params.store },
  });

  setTheme(settings.theme);

  CrewStore.init(settings);

  return context;
};

export default {
  id: 'appShell',
  HydrateFallback: Spinner,
  handle: {
    middleware,
  },
  loader,
  ErrorBoundary: ErrorPage,
  Component,
  children: [CrewLookup, GspLookup, Reorder, catchallRoute],
} satisfies RouteObject;
