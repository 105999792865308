import '../sentry.config';

import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { registerSW } from 'virtual:pwa-register';
import app from './app';
import env from './env';
import './index.css';
import supportedBrowsers from './supportedBrowsers';
import { CORSO_URL } from './utils/constants';
import { dataStrategy } from './utils/routing';

Sentry.setTag(
  'isUnsupportedBrowser',
  !supportedBrowsers.test(navigator.userAgent),
);
/** If `VITE_MSW` is set to any truthy value, and we're in development mode, start the mock service worker. */
async function enableMocking() {
  if (!import.meta.env.DEV) return;
  if (!env.VITE_MSW) return;

  const { worker } = await import('~/mocks/browser');
  await worker.start();
}

const pollForUpdates = () => {
  registerSW({
    immediate: true,
    onRegisteredSW: (serviceWorkerScriptUrl, registration) => {
      const intervalMs = 1_000 * 60 * 1; // 1 minutes

      if (!registration) return;

      setInterval(() => {
        const hasConnection = 'connection' in navigator && navigator.onLine;
        const isInstalling = !!registration.installing;
        const skipInterval = !hasConnection || isInstalling;

        if (skipInterval) return;

        fetch(serviceWorkerScriptUrl, {
          cache: 'no-store',
          headers: { cache: 'no-store', 'cache-control': 'no-cache' },
        })
          .then((response) =>
            response.ok ?
              registration.update()
            : Promise.reject(
                new Error('Failed to fetch service worker script', {
                  cause: {
                    status: response.status,
                    statusText: response.statusText,
                  },
                }),
              ),
          )
          .catch((error) => {
            // eslint-disable-next-line no-console -- we want to log this as a warning
            console.warn(error);
          });
      }, intervalMs);
    },
  });
};

// eslint-disable-next-line @typescript-eslint/require-await
export const configureServices = async () => {
  if (import.meta.env.PROD) {
    pollForUpdates();
  }

  await enableMocking();
};

export const getRootElement = () => {
  const root = document.getElementById('root');

  if (!root) {
    throw new Error('#root Element Missing');
  }

  return root;
};

export const removeSplashScreenListener = (root: HTMLElement) => {
  root.addEventListener(
    'animationend',
    () => {
      const splash = document.getElementById('splash');
      if (splash) {
        document.body.removeChild(splash);
      }
    },
    { once: true },
  );

  return root;
};

export const configureEmbedding = (root: HTMLElement) => {
  if (window.parent !== window) {
    const checkHeight = () => {
      const body = document.getElementById('app-body');
      // use parentElement of the body because it includes the padding to protect the shadows
      if (!body?.parentElement) return;

      if (root.offsetHeight === body.parentElement.offsetHeight) return;

      //! do not modify the postMessage type without updating the app.liquid consumer
      window.parent.postMessage(
        {
          type: 'CORSO_HEIGHT_CHANGE',
          height: body.parentElement.offsetHeight,
        },
        '*',
      );
    };
    const observer = new MutationObserver(checkHeight);

    observer.observe(root, {
      subtree: true,
      childList: true,
      attributes: true,
    });
    window.addEventListener('resize', checkHeight);
    document.body.addEventListener(
      'load',
      (e) => {
        if (e.target instanceof HTMLElement && e.target.tagName === 'IMG') {
          checkHeight();
        }
      },
      true,
    );
  }

  return root;
};

export const bootstrapReact = (root: HTMLElement) => {
  ReactDOM.createRoot(root).render(
    <React.StrictMode>
      <RouterProvider
        future={{
          v7_startTransition: true,
        }}
        router={Sentry.wrapCreateBrowserRouterV6(createBrowserRouter)([app], {
          future: {
            v7_partialHydration: true,
            v7_fetcherPersist: true,
            v7_normalizeFormMethod: true,
            v7_skipActionErrorRevalidation: true,
            v7_relativeSplatPath: true,
          },
          dataStrategy,
        })}
      />
    </React.StrictMode>,
  );
};

// * Start the application
configureServices()
  .then(getRootElement)
  .then(configureEmbedding)
  .then(removeSplashScreenListener)
  .then(bootstrapReact)
  .catch(() => {
    window.location.href = CORSO_URL;
  });
