import { useNavigation } from 'react-router-dom';
import Alert from '~/components/alert';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import Form from '~/components/form';
import Page from '~/components/page';
import { ResolutionMethod } from '~/utils/constants';
import { useIsLoading } from '~/utils/routing';
import {
  FIELD_KEY,
  useRefundOptionsActionResult,
} from './refund-options-action';
import { useRefundOptionsData } from './refund-options-loader';

export default function RefundOptions() {
  const {
    isGiftCardEnabled,
    isRefundEnabled,
    hasIncentive,
    incentive,
    giftCardTextContent,
    refundTextContent,
    refund,
    total,
  } = useRefundOptionsData();
  const actionResult = useRefundOptionsActionResult();
  const isLoading = useIsLoading();
  const navigation = useNavigation();

  return (
    <Page headline="How would you like your money back?">
      {isGiftCardEnabled && (
        <Card
          headline={`Gift card ${hasIncentive ? `with ${incentive} bonus` : ''}`}
        >
          <p className="text-gray-500">{giftCardTextContent}</p>
          <Form method="PUT">
            <input
              type="hidden"
              hidden
              name={FIELD_KEY}
              value={ResolutionMethod.giftCard}
            />
            <Submit
              loading={
                isLoading &&
                navigation.formData?.get(FIELD_KEY) ===
                  ResolutionMethod.giftCard
              }
            >
              Gift Card ({total})
            </Submit>
          </Form>
        </Card>
      )}
      {isRefundEnabled && (
        <Card headline="Original payment method">
          <p className="text-gray-500">{refundTextContent}</p>
          <Form method="PUT">
            <input
              type="hidden"
              hidden
              name={FIELD_KEY}
              value={ResolutionMethod.refund}
            />
            <Submit
              variant="outlined"
              loading={
                isLoading &&
                navigation.formData?.get(FIELD_KEY) === ResolutionMethod.refund
              }
            >
              Original Payment Method ({refund})
            </Submit>
          </Form>
        </Card>
      )}

      {actionResult && !actionResult.ok && (
        <Alert variant="error" title="Problem submitting refund option">
          <p>{actionResult.form?.resolution}</p>
        </Alert>
      )}
    </Page>
  );
}
