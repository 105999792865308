import Submit from '~/components/button-submit';
import Card from '~/components/card';
import Form from '~/components/form';
import Field from '~/components/form-controls/field';
import RadioGroup, {
  ButtonOption,
} from '~/components/form-controls/radio-group';
import Page from '~/components/page';
import { useReasonActionData } from './reason-action';
import { useReasonLoaderData } from './reason-loader';

export default function Reason() {
  const { reasons, selectedReason } = useReasonLoaderData();
  const actionResult = useReasonActionData();

  return (
    <Page
      headline="What Happened?"
      title="Tell us what happened to your package."
    >
      <Card>
        <Form>
          <RadioGroup label="Reorder reasons" showLabel={false}>
            {reasons.map(({ value, label }) => (
              <ButtonOption
                key={value}
                value={value}
                name="reason"
                required
                defaultChecked={value === selectedReason}
              >
                {label}
              </ButtonOption>
            ))}
          </RadioGroup>

          {actionResult?.ok === false && (
            <Field.ErrorText>{actionResult.message}</Field.ErrorText>
          )}
          <Submit>Next</Submit>
        </Form>
      </Card>
    </Page>
  );
}
