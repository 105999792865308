import AddressFields from '~/components/address-fields';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import Form from '~/components/form';
import Page from '~/components/page';
import { useIsLoading } from '~/utils/routing';
import { useAddressData } from './edit-address-loader';

export default function Address() {
  const { address } = useAddressData();
  const isLoading = useIsLoading();

  return (
    <Page headline="Update Address">
      <Card>
        <Form>
          <AddressFields address={address} />
          <Submit loading={isLoading}>Continue</Submit>
        </Form>
      </Card>
    </Page>
  );
}
