import Badge from '~/components/badge';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import Form from '~/components/form';
import RadioGroup, { CardOption } from '~/components/form-controls/radio-group';
import { CarrierIcon } from '~/components/icon';
import Page from '~/components/page';
import { ReturnShipmentType } from '~/utils/constants';
import { FIELD_KEY } from './return-methods-action';
import { useReturnMethodsData } from './return-methods-loader';

function EscapeHatch() {
  return (
    <Card headline="Sorry about that..." className="mt-8">
      <p>
        It looks like this return can&apos;t be handled automatically, please
        reach out to our team for help.
      </p>
    </Card>
  );
}

export default function ReturnMethods() {
  const { methods } = useReturnMethodsData();

  if (!methods.length) {
    return <EscapeHatch />;
  }

  return (
    <Page headline="How would you like to return your items?">
      <Form>
        <Card>
          <RadioGroup label="Return Methods" showLabel={false}>
            <div className="flex flex-col gap-4">
              {methods.map((method) => (
                <CardOption
                  key={method.type}
                  value={JSON.stringify(method)}
                  name={FIELD_KEY}
                  defaultChecked={method.type === ReturnShipmentType.label}
                  aria-labelledby={`return-method-${method.type}-label`}
                  aria-describedby={`return-method-${method.type}-description`}
                  required
                  className="flex items-center justify-between px-6 py-4"
                >
                  <>
                    <div className="flex flex-col gap-2">
                      <div id={`return-method-${method.type}-label`}>
                        {method.label}
                        {method.type === ReturnShipmentType.label && (
                          <Badge className="ml-2 border-green-300 bg-green-100 text-green-800">
                            Fastest
                          </Badge>
                        )}
                      </div>
                      <span
                        id={`return-method-${method.type}-description`}
                        className="text-gray-500"
                      >
                        {method.description}
                      </span>
                    </div>
                    <CarrierIcon
                      carrier={
                        method.type === ReturnShipmentType.label ?
                          method.shipmentFromPlatform.rate.carrier
                        : ''
                      }
                      className="size-8"
                    />
                  </>
                </CardOption>
              ))}
            </div>
          </RadioGroup>
        </Card>
        <Submit>Next</Submit>
      </Form>
    </Page>
  );
}
