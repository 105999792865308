import { CrewCustomerApi } from 'corso-types';
import { StatusCodes } from 'http-status-codes';
import pRetry, { AbortError } from 'p-retry';
import { generatePath } from 'react-router-dom';
import { z } from 'zod';
import env from '~/env';
import { AppType } from './constants';
import { determineFileType } from './file';
import { toCurrency } from './formatters';
import { identifyUser, logError } from './observability';
import { isApiError, mediaAssetSchema } from './schemas';
import { AsyncReturnType, Simplify } from './types';

const baseUrl = env.VITE_CREW_API_URL;
export type ApiPath = CrewCustomerApi.ApiPath;

type RequestConfig<
  Path extends CrewCustomerApi.ApiPath,
  Method extends CrewCustomerApi.MethodOfPath<Path>,
> = {
  path: Path;
  method: Method;
} & (CrewCustomerApi.RequestParams<Path, Method> extends never ?
  { params?: never }
: { params: CrewCustomerApi.RequestParams<Path, Method> }) &
  (CrewCustomerApi.RequestBody<Path, Method> extends never ? { body?: never }
  : { body: CrewCustomerApi.RequestBody<Path, Method> }) &
  (CrewCustomerApi.RequestQuery<Path, Method> extends never ? { query?: never }
  : { query: CrewCustomerApi.RequestQuery<Path, Method> });

type RequestCreate<
  Path extends CrewCustomerApi.ApiPath,
  Method extends CrewCustomerApi.MethodOfPath<Path>,
> = Simplify<Pick<RequestConfig<Path, Method>, 'path' | 'method'>>;

type RequestParams<
  Path extends CrewCustomerApi.ApiPath,
  Method extends CrewCustomerApi.MethodOfPath<Path>,
> = Simplify<Pick<RequestConfig<Path, Method>, 'params' | 'query' | 'body'>>;

//! use AbortError to cancel retries. If we get a response from the server we want to abort the retry
const defaultErrorMessage =
  'Something went wrong, please try again. If problem persists, please contact us.';

const handleNotOkResponse = (res: Response, body: unknown) => {
  const message =
    // eslint-disable-next-line no-nested-ternary
    isApiError(body) ? body.message
      // eslint-disable-next-line no-nested-ternary
    : res.status === StatusCodes.FORBIDDEN ? 'Sorry, not authorized'
    : res.status === StatusCodes.NOT_FOUND ? 'Item not found'
    : defaultErrorMessage;

  return Promise.reject(
    new AbortError(
      new Error(message, { cause: `Response not ok: ${res.status}` }),
    ),
  );
};

const abortOnSyntaxError = (cause: unknown) => {
  const error =
    cause instanceof SyntaxError ?
      new AbortError(new Error(defaultErrorMessage, { cause }))
    : cause;

  return Promise.reject(error);
};

export const parseResponse = <Data>(res: Response) => {
  if (res.status === 204) {
    return Promise.resolve(null) as Promise<Data>; // ! Assuming API contracts for Data to be nullable when 204
  }

  // some api responses (400s) are not json which causes a syntax errors
  const content =
    res.headers.get('Content-Type')?.includes('application/json') ?
      res.json()
    : res.text();

  return content
    .then((body: unknown) =>
      res.ok ? (body as Data) : handleNotOkResponse(res, body),
    )
    .catch(abortOnSyntaxError);
};

/**
 * ! Think the retry isn't happening on Safari and/or iOS due to a conflict with Sentry.
 * ! using this to bypass p-retry's isNetworkError check
 * ! see (https://github.com/sindresorhus/is-network-error/issues/7)
 * ! this is ok with current implementation of request as the only case would be a network error,
 * ! but if we start using fetchInit, we need to revisit this
 */
const bypassPRetryIsNetworkError = (cause: unknown) => {
  const error =
    cause instanceof TypeError ? new Error('Network error', { cause }) : cause;

  return Promise.reject(error);
};

const compileUrl = ({
  path,
  params,
  query,
}: {
  path: string;
  params: Record<string, unknown> | undefined;
  query: Record<string, unknown> | undefined;
}) => {
  const url = new URL(`${baseUrl}${generatePath(path, params)}`);

  Object.entries(query ?? {})
    .filter(([, value]) => value !== null && value !== undefined)
    // ! this coercion is not safe, but query params should must always be interchanged as string values
    .map(([key, value]) => [key, String(value).trim()] as const)
    .forEach(([key, value]) => url.searchParams.append(key, value));

  return url.toString();
};

export const request =
  <
    Path extends CrewCustomerApi.ApiPath,
    Method extends CrewCustomerApi.MethodOfPath<Path>,
  >({
    path,
    method,
  }: RequestCreate<Path, Method>) =>
  (
    { params, query, body }: RequestParams<Path, Method>,
    fetchInit?: Omit<RequestInit, 'method' | 'body'>,
  ) => {
    const { headers, ...init } = fetchInit ?? {};
    const requestInit = {
      method,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...headers,
      },
      ...init,
      ...(body && { body: JSON.stringify(body) }),
    };

    return pRetry(
      () =>
        fetch(compileUrl({ path, params, query }), requestInit)
          .then(parseResponse<CrewCustomerApi.ResponseBody<Path, Method>>)
          .catch(bypassPRetryIsNetworkError),
      {
        retries: 2,
        // ! needs the 'Network error' override in the fetch catch
        // * any error coming from 'parseResponse' should be an AbortError so then from the fetch catch will be an Error 'Network error'
        shouldRetry: (error) => !(error instanceof AbortError),
      },
    );
  };

const fetchCrewOrder = request({
  path: '/:storefrontId/order',
  method: 'get',
});

// TODO combine with fetchProtectionOrder -- the types were no happy when trying it before downstream consumers couldn't infer the type
const processCrewOrder = (order: AsyncReturnType<typeof fetchCrewOrder>) => {
  const { email, orderNo, idFromPlatform, currencyCode, storeId, storeName } =
    order;

  identifyUser({
    email,
    storeName,
    orderNo,
    orderIdFromPlatform: idFromPlatform,
    storeId,
    appType: AppType.crew,
  });

  const formatPrice = toCurrency(currencyCode);

  return {
    ...order,
    lineItems: order.lineItems.map((item) => ({
      ...item,
      unitPriceDisplay: formatPrice(item.unitPrice),
      remainingReturnableQuantity: item.currentQuantity,
    })),
  };
};

const fetchProtectionOrder = request({
  path: '/protection/order',
  method: 'get',
});

const processProtectionOrder = (
  order: AsyncReturnType<typeof fetchProtectionOrder>,
) => {
  const { email, orderNo, idFromPlatform, storeId, storeName, currencyCode } =
    order;

  identifyUser({
    email,
    orderNo,
    orderIdFromPlatform: idFromPlatform,
    storeId,
    storeName,
    appType: AppType.gsp,
  });

  const formatPrice = toCurrency(currencyCode);

  return {
    ...order,
    lineItems: order.lineItems.map((item) => ({
      ...item,
      unitPriceDisplay: formatPrice(item.unitPrice),
    })),
  };
};

const fetchSignedUrl = request({
  path: '/:storefrontId/signed-url',
  method: 'get',
});

type MediaAsset = z.infer<typeof mediaAssetSchema>;
const uploadToCDN =
  (file: File) => (signedUrl: AsyncReturnType<typeof fetchSignedUrl>) =>
    pRetry(
      () =>
        fetch(signedUrl.signedUploadUrl, {
          method: 'PUT',
          body: file,
          // Presigned URLs must have this header set when using AWS S3 client on DigitalOcean @see https://stackoverflow.com/questions/66555915/cannot-upload-files-with-acl-public-read-to-digital-ocean-spaces
          headers: { 'x-amz-acl': 'public-read', 'Content-Type': file.type },
        }).then((resp) => {
          if (resp.ok) {
            return {
              name: signedUrl.uploadFilename,
              src: signedUrl.url,
              type: determineFileType(file),
            } satisfies MediaAsset;
          }

          logError(new Error('CDN file upload error'), {
            extra: {
              fileName: signedUrl.uploadFilename,
              status: resp.status,
              statusText: resp.statusText,
            },
          });

          return Promise.reject(new AbortError(file.name));
        }),
      { retries: 1 },
    );

const uploadFile = ({
  params,
  query,
  body,
}: {
  params: Parameters<typeof fetchSignedUrl>[0]['params'];
  query: Simplify<
    Pick<Parameters<typeof fetchSignedUrl>[0]['query'], 'destination'>
  >;
  body: File;
}): Promise<
  { success: true; data: MediaAsset } | { success: false; error: Error }
> =>
  fetchSignedUrl({
    params,
    query: {
      ...query,
      filename: body.name,
      contentType: body.type,
    },
  })
    .then(uploadToCDN(body))
    .then((data) => ({ success: true as const, data }))
    .catch(() => ({
      success: false as const,
      error: new Error('File upload failed.'),
    }));

const checkBeforeClaimSubmissionRequest = request({
  path: '/:storefrontId/automations/before-claim-submission',
  method: 'post',
});

export default {
  fetchSettings: request({
    path: '/settings/:slug',
    method: 'get',
  }),

  fetchRegistrationProducts: request({
    path: '/:storefrontId/registration/product',
    method: 'get',
  }),

  fetchCrewOrder: (...params: Simplify<Parameters<typeof fetchCrewOrder>>) =>
    fetchCrewOrder(...params).then(processCrewOrder),

  fetchProductVariantInfo: request({
    path: '/:storefrontId/product/:idFromPlatform',
    method: 'get',
  }),

  fetchCrewClaim: request({
    path: '/:storefrontId/claim/:claimExternalId',
    method: 'get',
  }),

  createCrewClaim: request({
    path: '/:storefrontId/claim',
    method: 'post',
  }),

  createRegistration: request({
    path: '/:storefrontId/registration',
    method: 'post',
  }),

  createShipmentMethodsQuote: request({
    path: '/:storefrontId/return-shipment/quote',
    method: 'post',
  }),

  createShipment: request({
    path: '/:storefrontId/return-shipment',
    method: 'post',
  }),

  createPaymentIntent: request({
    path: '/:storefrontId/create-payment-intent',
    method: 'post',
  }),

  fetchProtectionOrder: (
    params: Simplify<Parameters<typeof fetchProtectionOrder>[0]>,
  ) => fetchProtectionOrder(params).then(processProtectionOrder),

  createProtectionClaim: request({
    path: '/:storefrontId/shipping-protection/claim',
    method: 'post',
  }),

  uploadFile,

  // * business hooks

  checkBeforeClaimSubmission: <
    Args extends Parameters<typeof checkBeforeClaimSubmissionRequest>[0],
    Kind extends Args['body']['kind'],
  >(
    args: Args,
  ) =>
    checkBeforeClaimSubmissionRequest(args) as Promise<
      Extract<
        AsyncReturnType<typeof checkBeforeClaimSubmissionRequest>,
        { kind: Kind }
      >
    >,

  checkAfterReasonSelection: request({
    path: '/:storefrontId/automations/after-reason-selection',
    method: 'get',
  }),

  confirmAfterReasonSelection: request({
    path: '/:storefrontId/automations/after-reason-selection',
    method: 'post',
  }),
};
