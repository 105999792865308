import AddressFields from '~/components/address-fields';
import Alert from '~/components/alert';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import Form from '~/components/form';
import Page from '~/components/page';
import { useEditAddressActionResult } from './edit-action';
import { useEditAddressData } from './edit-loader';

// TODO: Move to 2 column layout like register page
export default function EditAddress() {
  const { address } = useEditAddressData();
  const actionResult = useEditAddressActionResult();

  return (
    <Page headline="Update Address">
      <Card>
        <Form>
          <AddressFields address={address} />
          {actionResult?.ok === false && (
            <Alert variant="error" title="Invalid address">
              Please check your information is correct.
            </Alert>
          )}
          <Submit>Next</Submit>
        </Form>
      </Card>
    </Page>
  );
}
